import {
  Button,
  Card,
  CardContent,
  Grid,
  Checkbox,
  Divider,
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Drawer,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  List,
  Alert,
  useTheme,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import _, { filter, head, max, pad } from 'lodash';
import { useHistory } from 'react-router-dom';
import { subDays, addDays } from 'date-fns';
import { useErrorHandler } from 'react-error-boundary';
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useForm, useWatch, Controller, Form } from 'react-hook-form';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AG_GRID_LOCALE_ES } from '@ag-grid-community/locale';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { CardPaper } from '../../../components';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  LoadingWidget,
  SaveAction,
  SimpleIconButton,
  SelectMultipleWidget2,
  TextFieldWidgetDateController2,
  ButtonAction,
} from '../../../components';
import { FiltersContext } from '../../../context';
import {
  alphabeticalSort,
  birthConvert,
  dashAdd,
  dashDelete,
  dateConvert,
  dateConvertInverse,
  dateFormatIso,
  dateFrontConvert,
  dateFrontConvertWithFormat,
  getOrchardVarietiesDeep,
  getUniqueValuesMaster,
  numberFormatDecimals,
} from '../../../helpers';
import { FormPrompt } from '../../../helpers/FormPrompt';
import { MobileFilters } from 'containers/Library';
// import customPanel from './CustomPanel';
import './styles.css';
import MOCKDATA from './weeks_2024_data.json';
import MOCKDATAWEEKS from './weeks_2024_updated.json';

const drawerWidth = '40vw';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  titleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
  },
  subtitleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
    marginBottom: '15px !important',
    marginTop: '20px !important',
  },
  weekTitleStyle: {
    fontSize: '18px !important',
    fontWeight: 600 + ' !important',
    // marginTop: '20px !important',
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    maxWidth: '90px !important',
    minWidth: '70px !important',
  },
  dividerRow: {
    marginTop: 30,
  },
  iconText: {
    marginRight: 10,
  },
  OddColStyle: {
    backgroundColor: 'rgba(227,235,237,.3)',
  },
  tableStyle: {
    '& tbody tr:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '& tbody tr td': {
      borderTop: 'none !important',
    },
  },
  varietyNameStyle: {
    fontWeight: 600,
    fontSize: 15,
    paddingTop: 10 + 'px !important',
    width: '120px',
  },
  legendStyle: {
    position: 'absolute',
    right: 0,
    top: 10,
    // media query para pantallas menores a 768px
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 0,
      marginBottom: 10,
    },
  },
  daysTableStyle: {
    // borderRight: '1px solid #e3e3e3',
    // borderBottom: '1px solid #e3e3e3',
    alignItems: 'center',
    verticalAlign: 'middle',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: '8px',
      padding: '0px 10px',
      '& i': {
        fontSize: '17px',
        marginRight: '9px',
      },

      '& i.fa-check-square': {
        color: theme.palette.success.main,
      },
    },
  },
  rowVarietiesStyle: {
    marginBottom: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '& .varietyBox': {
      paddingLeft: '5px',
      paddingRight: '5px',
      width: '408px',
      backgroundColor: 'rgba(245,245,245,.4)',
      marginRight: '8px',
      marginBottom: '5px',
      borderTop: '3px solid',
      borderTopColor: theme.palette.primary.main,
    },
    '& .varietyBox table': {
      marginBottom: '0px',
    },
    // '& .varietyBox table td:not(:first-child)': {
    // '& .varietyBox table td': {
    //   textAlign: 'center',
    // },
    '& .varietyBox table td': {
      width: '25%',
      overflow: 'hidden',
      wordBreak: 'break-all',
      whiteSpace: 'normal',
      textAlign: 'center',
    },
    // '& .varietyBox table td:nth-child(1)': {
    //   width: '110px',
    //   overflow: 'hidden',
    //   wordBreak: 'break-all',
    //   whiteSpace: 'normal',
    // },
    '& .varietyBox table td.varietyTitleStyle': {
      textAlign: 'center',
      padding: '5px 0px',
    },
    '& .varietyBox table td:nth-child(1)': {
      borderRight: '1px solid #e3e3e3',
    },
    '& .varietyBox table td:nth-child(2)': {
      borderRight: '1px solid #e3e3e3',
    },
    '& .varietyBox table td p': {
      fontSize: '12px',
    },
  },
  weekContainerTitleStyle: {
    backgroundColor: '#d9eef4',
    padding: '5px 8px',
    '& h6': {
      // color: 'white',
      padding: '5px 0px',
      marginBottom: '0px',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 56,
    },
  },
}));

/**
 * Flattens the endpoint data by iterating through the nested structure and creating a flat array of objects.
 * @param {Array} data - The data to be flattened.
 * @returns {Array} - The flattened data.
 */
function flattenEndpointDataHandler(data) {
  const result = [];

  data.forEach((item) => {
    item.specie.forEach((specie) => {
      specie.productLine.forEach((productLine) => {
        productLine.variety.forEach((variety) => {
          result.push({
            orchard: {
              label: item.label,
              value: item.value,
            },
            agroBusiness: {
              label: item.agroBusiness,
              value: item.agroBusinessValue,
            },
            group: {
              label: item.group,
              value: item.groupValue,
            },
            technicBoss: {
              label: item.technicBoss,
              value: item.technicBossValue,
            },
            specie: {
              label: specie.label,
              value: specie.value,
            },
            productLine: {
              label: productLine.label,
              value: productLine.value,
            },
            variety: {
              label: variety.label,
              value: variety.value,
            },
          });
        });
      });
    });
  });

  return result;
}

const endPointName = 'v1/planningHarvest/bi-semanal';
const endPointNamePost = 'v1/planningHarvest/create-bi-semanal';
const endPointFilters = 'v1/libraryExternal/specie';

ModuleRegistry.registerModules([
  FiltersToolPanelModule,
  ClientSideRowModelModule,
  RangeSelectionModule,
  MenuModule,
  MultiFilterModule,
  MasterDetailModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ExcelExportModule,
  SetFilterModule,
  ClipboardModule,
]);

const getSelectWeeks = (data) => {
  return data.map((ele) => {
    return {
      label: ele.name,
      value: ele.value,
    };
  });
};

/**
 * Filters a weeks array to include only the current period containing today and future periods
 * @param {Array} weeksData - The array of week objects from MOCKDATAWEEKS
 * @returns {Array} - Filtered array containing current and future week objects
 */
const getCurrentAndFutureWeeks = (weeksData) => {
  // Get today's date
  const today = new Date();

  // Find the current period that contains today's date
  const currentPeriodIndex = weeksData.findIndex((week) => {
    const sinceDate = new Date(week.since);
    const untilDate = new Date(week.until);

    // Check if today falls within this period
    return today >= sinceDate && today <= untilDate;
  });

  // If no current period found (maybe we're in a gap between periods)
  // Find the next upcoming period
  if (currentPeriodIndex === -1) {
    const nextPeriodIndex = weeksData.findIndex((week) => {
      const sinceDate = new Date(week.since);
      return today < sinceDate;
    });

    // If a future period exists, return from that one forward
    if (nextPeriodIndex !== -1) {
      return weeksData.slice(nextPeriodIndex);
    } else {
      // If no future periods, return empty array
      return [];
    }
  }

  // Return from current period forward
  return weeksData.slice(currentPeriodIndex);
};

const CheckBiWeekly = (props) => {
  const theme = useTheme();

  const classes = useStyles();

  const history = useHistory();

  const { window } = props;

  const axiosContext = useContext(AxiosContext);

  const {
    moduleUser,
    userClient,
    userOrchard,
    userId,
    userFullname,
    userIdProfile,
  } = useContext(Context);

  const errorHandler = useErrorHandler();

  // crear un ref de alert
  const alertRef = useRef(false);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const {
    state: {
      userOrchard_filter,
      orchard_filter,
      orchard_id_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  // si specie_filter es === "Uva" debe redireccionar a '/check-pentaweekly'
  // useEffect(() => {
  //   if (specie_filter === 'Uva') {
  //     history.push('/check-pentaweekly');
  //   }
  // }, [specie_filter]);

  const moduleOrchardFilter = 'statusOrchardPlanning';

  const [filtersFlattenDataState, setFiltersFlattenDataState] = useState([]);

  const [filterDataTechnicBossState, setFilterDataTechnicBossState] = useState(
    []
  );

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const [dataFilterState, setDataFilterState] = useState([]);

  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const [loadingVarietiesState, setLoadingVarietiesState] = useState(false);

  const [openDialogState, setOpenDialogState] = useState(false);

  // dates

  const actualYear = new Date().getFullYear();

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const [minDateValidationState, setMinDateValidationState] = useState(
    new Date(`${actualYear}-01-01`)
  );
  const [maxDateValidationState, setMaxDateValidationState] = useState(
    new Date(`${actualYear}-12-31`)
  );

  const [minDateState, setminDateState] = useState(null);

  const [loadingSubmitFilterState, setLoadingSubmitFilterState] =
    useState(false);

  const [loadingSubmitState, setLoadingSubmitState] = useState(false);

  const [noMessageKilosState, setNoMessageKilosState] = useState(false);

  const [successSubmitState, setSuccessSubmitState] = useState(false);

  const [copyButtonState, setCopyButtonState] = useState(false);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);

  const [excelLoadingState, setExcelLoadingState] = useState(false);

  const [excelSuccessDownloadState, setExcelSuccessDownloadState] =
    useState(false);

  const [styleState, setStyleState] = useState({
    height: 800,
    width: '100%',
    minHeight: 500,
  });

  const [fullScreenState, setFullScreenState] = useState(false);

  const [totalDataKilosState, setTotalDataKilosState] = useState(0);

  const [visibleColumnsState, setVisibleColumnsState] = useState([]);
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [totalRow, setTotalRow] = useState({});
  const paginationPageSizeSelector = [20, 50, 100, 500, 1000];
  const [quickFilterText, setQuickFilterText] = useState(''); // estado para el filtro de busquedageneral
  const [anchorEl, setAnchorEl] = useState(null);

  const [customGroupColsState, setCustomGroupColsState] = useState('0');

  const [sinceOptionsState, setSinceOptionsState] = useState(null);
  const [untilOptionsState, setUntilOptionsState] = useState(null);

  const [errorDataState, setErrorDataState] = useState(false);

  // crear un ref para mantener el custom actual
  const customGroupColsRef = useRef('0');

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const localeText = AG_GRID_LOCALE_ES;

  //-------------- FUNCIONES PARA LA TABLA

  // suma de totales
  const sumTotalWeeks = (rowData) => {
    return rowData.reduce((total, row) => {
      return total + (row.totalWeeks || 0);
    }, 0);
  };

  // funcion para limpiar la selección de celdas
  const clearFocusSelection = () => {
    if (gridApiRef.current && gridApiRef.current) {
      gridApiRef.current.clearFocusedCell();
    }
  };

  // funcion para edición de celdas
  const onCellValueChanged = (params) => {
    const { data, colDef, newValue, oldValue } = params;
    const { field } = colDef;

    if (newValue === null || newValue === '') {
      data[`edited_${colDef.field}`] = false; // Marca la celda como editada
      params.node.setDataValue(colDef.field, oldValue); // Restaura el valor anterior
    } else {
      if (newValue !== oldValue && newValue !== '') {
        data[`edited_${colDef.field}`] = true; // Marca la celda como editada
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: [colDef.field],
          force: true,
        });
      }

      alertRef.current = true;
      setCopyButtonState(true);

      // Actualizar el estado de rowData
      const updatedRowData = [...rowData];

      // console.log(
      //   updatedRowData,
      //   '<<<<<-------------------updatedRowData',
      //   data,
      //   '<<<<<-------------------data'
      // );

      const rowIndex = updatedRowData.findIndex((row) => row.id === data.id);
      updatedRowData[rowIndex] = data;

      setRowData(updatedRowData);
    }
  };

  // procesa las filas con su detalle histórico
  const processDataRow = (data) => {
    return data.map((item, index) => {
      console.log(data, '-------------data');

      const transformed = {
        id: `${item.userId}_${item.username}`, // Genera un id incremental
        technicBoss: item.technicBoss, // Toma el nombre del jefe técnico
        technicBossValue: item.technicBossValue,
        username: item.username,
        nameUser: item.nameUser,
        lastNameUser: item.lastNameUser,
        userId: item.userId,
        specieValue: item.specieValue,
      };

      // Agrega cada valor de la propiedad "id" de "bisemanal" como clave en el objeto transformado
      item.biSemanal.forEach((week) => {
        transformed[week.value] = week.confirmed; // Añade propiedades dinámicas usando el ID
      });

      return transformed;
    });
  };

  const generateWeeksColumns = (weekData) => {
    // console.log(weekData, '-------------');

    return weekData.map((week) => {
      // necesito que la propiedad editable sea true solo si la fecha de HOY está entre la fecha de week.since y week.until de week
      const today = new Date();
      const sinceDate = new Date(week.since);
      const untilDate = new Date(week.until);

      const editableCol = today >= sinceDate;

      // console.log(editableCol, '<<<<<-------------------editableCol');

      return {
        headerName: week.name,
        field: week.value,
        editable: editableCol,
        filter: 'agMultiColumnFilter',
        suppressMovable: true,
        cellDataType: 'boolean',

        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },

        cellEditor: 'agCheckboxCellEditor',
        cellClassRules: {
          'edited-cell': (params) =>
            params.data[`edited_${params.colDef.field}`],
        },
        cellStyle: (params) => {
          if (params.node?.level) return '';
          if (!editableCol) {
            return {
              backgroundColor: '#ededed',
            };
          }
          return null;
        },
        cellRenderer: (params) => {
          return params.value ? (
            <CheckIcon style={{ color: '#81B214' }} />
          ) : (
            <CloseIcon style={{ color: '#A84448' }} />
          );
        },
      };
    });
  };

  function filterArrayByIdRange(data, sinceId, untilId) {
    const startIndex = data.findIndex((item) => item.value === sinceId);
    const endIndex = data.findIndex((item) => item.value === untilId);

    if (startIndex === -1 || endIndex === -1) {
      return [];
    }

    return data.slice(startIndex, endIndex + 1); // endIndex + 1 para incluir el último elemento
  }

  // procesa los datos y genera las columnas
  const processDataColumns = (since, until) => {
    // con el since y el until debe acortar el MOCKDATAWEEKS
    const limitRange = filterArrayByIdRange(MOCKDATAWEEKS, since, until);

    const weeksColumns = generateWeeksColumns(limitRange);

    const finalColumns = [
      {
        headerName: 'Jefe tecnico',
        field: 'technicBoss',
        colId: 'technicBoss',
        editable: false,
        suppressFillHandle: true,
        suppressMovable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agTextColumnFilter',
              filterParams: {
                defaultOption: 'contains',
                filterOptions: ['contains'],
              },
            },
            {
              filter: 'agSetColumnFilter',
              filterParams: {
                suppressMiniFilter: true,
              },
            },
          ],
        },
      },
      {
        colId: 'weeks',
        // headerGroup: 'caracter',
        headerName: 'Semanas',
        children: [
          // {
          //   headerName: '42/43',
          //   field: 'preConfirmedPlanning',
          //   editable: true,
          //   filter: 'agMultiColumnFilter',
          //   suppressMovable: true,
          //   cellDataType: 'boolean',

          //   filterParams: {
          //     filters: [
          //       // {
          //       //   filter: 'agTextColumnFilter',
          //       //   filterParams: {
          //       //     defaultOption: 'contains',
          //       //     filterOptions: ['contains'],
          //       //   },
          //       // },
          //       {
          //         filter: 'agSetColumnFilter',
          //         filterParams: {
          //           suppressMiniFilter: true,
          //         },
          //       },
          //     ],
          //   },

          //   cellEditor: 'agCheckboxCellEditor',
          //   // cellRenderer: 'agCheckboxCellRenderer',
          //   // cellRendererParams: {
          //   //   disabled: true,
          //   // },
          //   cellRenderer: (params) => {
          //     return params.value ? (
          //       <CheckIcon style={{ color: '#81B214' }} />
          //     ) : (
          //       <CloseIcon style={{ color: '#A84448' }} />
          //     );
          //   },
          // },
          // {
          //   headerName: '43/44',
          //   field: 'confirmedPlanning',
          //   editable: true,
          //   filter: 'agMultiColumnFilter',
          //   suppressMovable: true,
          //   cellDataType: 'boolean',

          //   filterParams: {
          //     filters: [
          //       // {
          //       //   filter: 'agTextColumnFilter',
          //       //   filterParams: {
          //       //     defaultOption: 'contains',
          //       //     filterOptions: ['contains'],
          //       //   },
          //       // },
          //       {
          //         filter: 'agSetColumnFilter',
          //         filterParams: {
          //           suppressMiniFilter: true,
          //         },
          //       },
          //     ],
          //   },

          //   cellEditor: 'agCheckboxCellEditor',
          //   // cellRenderer: 'agCheckboxCellRenderer',
          //   // cellRendererParams: {
          //   //   disabled: true,
          //   // },
          //   cellRenderer: (params) => {
          //     return params.value ? (
          //       <CheckIcon style={{ color: '#81B214' }} />
          //     ) : (
          //       <CloseIcon style={{ color: '#A84448' }} />
          //     );
          //   },
          // },
          ...weeksColumns,
        ],
      },
    ];

    return finalColumns;
  };

  // funcion para limpiar las celdas editadas
  const clearEditedCells = () => {
    // const updatedRowData = rowData.map((row) => {
    //   const updatedRow = { ...row };
    //   Object.keys(updatedRow).forEach((key) => {
    //     if (key.startsWith('edited_')) {
    //       delete updatedRow[key];
    //     }
    //   });
    //   return updatedRow;
    // });

    const transaction = {
      update: rowData.map((row) => {
        const updatedRow = { ...row };
        Object.keys(updatedRow).forEach((key) => {
          if (key.startsWith('edited_')) {
            delete updatedRow[key];
          }
        });
        return updatedRow;
      }),
    };

    // console.log(transaction, '<<<<<-------------------transaction');

    gridApiRef.current.applyTransaction(transaction);

    // setRowData(updatedRowData);
    setRowData(transaction.update);
    // gridApiRef.current.setRowData(updatedRowData);
  };

  // función para setear el fullScreen
  const setFullScreen = (fullScreenState) => {
    if (fullScreenState) {
      document.body.style.overflow = 'hidden';
      setStyleState({
        height: 'calc(100vh - 80px)',
        width: '100%',
        minHeight: 500,
        zIndex: 1250,
        top: 0,
        left: 0,
        position: 'fixed',
      });
    } else {
      document.body.style.overflow = '';
      setStyleState({
        height: 800,
        width: '100%',
        minHeight: 500,
        position: 'relative',
      });
    }
    setFullScreenState((prevState) => !prevState);
  };

  // confirma si debe tener master row, si tiene datos internos
  const isRowMaster = useCallback((dataItem) => {
    return dataItem ? dataItem.variantDetails.length > 0 : false;
  }, []);

  useEffect(() => {
    if (gridApiRef.current && customGroupColsState) {
      gridApiRef.current.openToolPanel('custom');
    }
  }, [customGroupColsState]);

  // genera el sidebar Custom
  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true, // desactivar el modo pivot
          suppressRowGroups: true, // desactivar la agrupación de filas
          suppressValues: true, // desactivar los valores
          suppressColumnFilter: true, // desactivar el filtro de busqueda en las columnas
          contractColumnSelection: true, // contraer la selección de columnas al incio
          suppressColumnSelectAll: true, // desactivar el check de selección de todas las columnas
        },
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
    ],
    position: 'right',
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();

  const [dataTableState, setDataTableState] = useState([]);

  const useTechnicBoss = useWatch({
    control,
    name: 'technicBoss',
  });

  const watchSince = watch('sinceWeek', '');
  // const watchUntil = watch('untilWeek', '');
  // const watchUntil = watch('until', '');

  // useEffect(() => {
  //   setUntilSelectedState(watchUntil);
  //   clearErrors('incorrectDates');
  // }, [watchUntil]);

  const getFilterAsync = async () => {
    setLoadingFiltersState(true);
    const objQuery = {
      clientValue: userClient.value,
      userOrchards: userOrchard,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointFilters,
        objQuery
      );

      // console.log(response.data, '<<<<<------responde nuevo end------');

      const { data, status } = response;

      if (status === 200) {
        data.orchard.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setDataFilterState(data.orchard);

        const flattenEndpointData = flattenEndpointDataHandler(data.orchard);

        setFiltersFlattenDataState(flattenEndpointData);

        const technicBossArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.technicBoss,
          };
        });

        const uniqueTechnicBoss = getUniqueValuesMaster(
          technicBossArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueTechnicBoss.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataTechnicBossState(uniqueTechnicBoss);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingFiltersState(false);
    }
    // setDataFilterState(MOCK_FILTER_DATA);
  };

  useEffect(() => {
    // getFilterVarietyAsync();

    getFilterAsync();

    const currentAndFutureWeeks = getCurrentAndFutureWeeks(MOCKDATAWEEKS);

    setSinceOptionsState(getSelectWeeks(currentAndFutureWeeks));

    setUntilOptionsState(getSelectWeeks(MOCKDATAWEEKS));
  }, []);

  // UseEffect para cambios de filtros
  useEffect(() => {
    const filterUpdatedData = filtersFlattenDataState.filter((ele) => {
      const technicBossFilter = useTechnicBoss
        ? ele?.technicBoss.value === useTechnicBoss.value
        : true;

      return technicBossFilter;
    });

    const technicBossArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.technicBoss,
      };
    });

    const uniqueTechnicBoss = getUniqueValuesMaster(
      technicBossArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueTechnicBoss.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataTechnicBossState(uniqueTechnicBoss);
  }, [useTechnicBoss]);

  const onSubmit = async (values) => {
    if (alertRef.current) {
      handleClickOpenDialog();
      return;
    }

    setNoMessageKilosState(false);

    const dateBegin = values.sinceWeek.value || '';
    // const dateEnd = values.untilWeek.value || '';

    // console.log(dateBegin, dateEnd, '<<<<<------------');

    // if (parseInt(dateBegin) > parseInt(dateEnd)) {
    //   // console.log(dateBeginFormat, dateEndFormat, '<<<<<------------');
    //   setError('incorrectDates', {
    //     type: 'manual',
    //     message: 'Bisemanal desde debe ser menor a bisemanal hasta',
    //   });
    //   return;
    // }

    setDataTableState([]);
    setLoadingSubmitFilterState(true);

    let technicBossValueFilter = '';

    // Si es ID jefe técnico filtra con su username
    if (
      userIdProfile === '66b35738fce7da894e2b2572' ||
      userIdProfile === '6422e08d26bae345b6309abb'
    ) {
      technicBossValueFilter = userFullname;
    } else {
      technicBossValueFilter = values.technicBoss?.value || '';
    }

    // AGREGADO de specie y productionLine
    const dataQuery = {
      clientValue: userClient.value,
      // orchardValue: values.orchard.value,
      // agroBusiness: values?.agroBusiness?.label,
      technicBossValue: technicBossValueFilter,
      specieValue: specie_filter,
      // agroBusinessValue: values?.agroBusiness?.value,
      weekSince: dateBegin,
      weekUntil: dateBegin,
    };

    // console.log(dataQuery, '---------dataQuery');

    // setDataTableState(MOCKDATA);
    // const rowDataTransform = processDataRow(MOCKDATA);

    // console.log(rowDataTransform, '<<<<<------rowDataTransform');

    // setRowData(rowDataTransform);

    // setVisibleColumnsState(processDataColumns(dateBegin, dateEnd));

    try {
      const response = await axiosContext.authAxios.post(
        endPointName,
        dataQuery
      );

      const { data, status } = response;

      // console.log(data, '<<<<<-------------------', paramsQuery);

      if (status === 200) {
        setDataTableState(data);

        console.log(data, '<<<<<------data del ENDPOINT');

        setVisibleColumnsState(processDataColumns(dateBegin, dateBegin));

        const rowDataTransform = processDataRow(data);

        // console.log(rowDataTransform, '<<<<<------rowDataTransform');

        setRowData(rowDataTransform);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingSubmitFilterState(false);
    }
    // setLoadingSubmitFilterState(false);
  };

  // console.log(rowData, '<<<<<------rowData');

  /*Funcionamiento de el boton de busqueda */
  const handleQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };

  const closeHandler = () => {
    setSuccessSubmitState(false);
  };

  const onResetSearch = () => {
    setQuickFilterText('');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /*Funcionamiento del boton de exportar Csv*/
  const onBtnExportCsv = () => {
    if (gridApiRef.current) {
      const date = new Date();
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        '0'
      )}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`; // DD-MM-YYYY
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS

      const docName = 'chequeo_bisemanal';

      const fileName = `${docName}_${formattedDate}_${formattedTime}.csv`;

      gridApiRef.current.exportDataAsExcel({
        fileName: fileName,
        processCellCallback: (params) => {
          const value = params.value;
          return typeof value === 'string' ? value.toUpperCase() : value;
        },
      });
    }
    handleMenuClose();
  };

  const onBtnExportExcel = () => {
    if (gridApiRef.current) {
      const date = new Date();
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        '0'
      )}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`; // DD-MM-YYYY
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS

      const docName = 'chequeo_bisemanal';

      const fileName = `${docName}_${formattedDate}_${formattedTime}.xlsx`;

      gridApiRef.current.exportDataAsExcel({
        fileName: fileName,
        processCellCallback: (params) => {
          const value = params.value;
          return typeof value === 'string' ? value.toUpperCase() : value;
        },
      });
    }
    handleMenuClose();
  };

  const handleClickOpenDialog = () => {
    setOpenDialogState(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogState(false);
  };

  const handleCloseAndConfirmDialog = () => {
    setCopyButtonState(false);
    alertRef.current = false;
    handleSubmit(onSubmit)();

    setOpenDialogState(false);
  };

  const convertRowDataToCells = (data) => {
    let result = [];

    data.forEach((item) => {
      // Desestructuramos los campos comunes

      console.log(item, '<<<<<-----------------item');

      const {
        technicBoss,
        technicBossValue,
        userId,
        username,
        nameUser,
        lastNameUser,
        specieValue,
      } = item;

      // Iteramos sobre las claves del objeto
      Object.keys(item).forEach((key) => {
        if (/^\d+$/.test(key)) {
          // Verificamos si es un ID de semana (solo números)

          // console.log(MOCKDATAWEEKS, '<<<<<------MOCKDATAWEEKS');

          // filtrar MOCKDATAWEEKS por key

          const weekData = MOCKDATAWEEKS.find((ele) => ele.value === key);

          result.push({
            technicBoss,
            technicBossValue, // Puedes ajustar si necesitas otro formato
            username,
            userId,
            nameUser,
            lastNameUser,
            season: weekData.season,
            specieValue: specie_filter || specieValue,
            value: key, // ID de la semana
            confirm: item[key], // Valor true/false
          });
        }
      });
    });

    return result;
  };

  const hasEmptySpecieValue = (array) => {
    return array.some(
      (item) => item.specieValue === '' || item.specieValue === null
    );
  };

  // guarda los datos
  const onSaveData = async () => {
    setLoadingSubmitState(true);

    clearFocusSelection();

    // console.log(rowData, '<<<<<------rowData');

    const newArray = convertRowDataToCells(rowData);
    // const newArray = [];

    console.log(newArray, '<<<<<------newArray');

    // const dataObj = {
    //   userId: userId,
    //   client: userClient?.label || '',
    //   clientValue: userClient?.value || '',
    //   days: newArray,
    // };

    if (!hasEmptySpecieValue(newArray)) {
      const dataObj = newArray;

      try {
        const response = await axiosContext.authAxios.post(
          endPointNamePost,
          dataObj
        );

        const { status } = response;

        if (status === 200) {
          setSuccessSubmitState(true);
          alertRef.current = false;
          setCopyButtonState(false);

          clearEditedCells();
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoadingSubmitState(false);
      }
    } else {
      setErrorDataState(true);
      setLoadingSubmitState(false);
    }
  };

  // const technicBossList = MOCKDATA.counterBoxes[0].technicBoss;
  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <>
          <>
            <BsRow class='no-gutters'>
              <BsCol class='col-md-12'>
                <Card elevation={0}>
                  <CardContent sx={{ p: 0 }}>
                    <>
                      {/* PARA AVISAR DATOS NO GUARDADOS */}
                      <FormPrompt hasUnsavedChanges={copyButtonState} />
                      <Dialog
                        open={openDialogState}
                        keepMounted
                        onClose={handleCloseDialog}
                        aria-describedby='alert-dialog-slide-description'
                      >
                        <DialogContent>
                          <DialogContentText id='alert-dialog-slide-description'>
                            Tiene datos editados sin guardar.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog}>Cancelar</Button>
                          <Button onClick={handleCloseAndConfirmDialog}>
                            Aceptar y Filtrar
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <>
                        <BsRow class='no-gutters'>
                          <BsCol class='col-md-12 pb-5'>
                            <div style={styleState}>
                              {errors.incorrectDates && (
                                <p
                                  style={{
                                    color: theme.palette.error.main,
                                    marginLeft: 10,
                                  }}
                                >
                                  {errors.incorrectDates.message}
                                </p>
                              )}
                              <div
                                // className='d-flex justify-content-between pb-3 pt-2'
                                className='d-flex flex-column flex-md-row justify-content-md-between justify-content-center pb-3 pt-2 gap-3'
                                style={{ backgroundColor: 'white' }}
                              >
                                <Box
                                  sx={{
                                    justifyContent: 'flex-start',
                                    display: 'flex',
                                  }}
                                >
                                  <form
                                    className='form-inline'
                                    autoComplete='on'
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div className='input-group ml-md-2'>
                                      {/* <TextFieldWidgetDateController2
                                        name='since'
                                        labeltext='Desde'
                                        placeholder='Desde'
                                        display='vertical'
                                        variant='outlined'
                                        defaultValue={new Date()}
                                        format='dd-MM-yyyy'
                                        style={{}}
                                        shouldDisableDate={shouldDisableDate}
                                        minDate={minCheck}
                                        maxDate={maxCheck}
                                        minheight={false}
                                        slotProps={{
                                          textField: {
                                            size: 'small',
                                          },
                                        }}
                                        sx={{
                                          width: '150px',
                                        }}
                                        // value={sinceSelectedState}
                                        // onChange={date => setSinceSelectedState(date)}
                                        control={control}
                                        errors={errors.since}
                                        // defaultValue={startColdDate}
                                      /> */}
                                      <SelectMultipleWidget2
                                        errors={errors.sinceWeek}
                                        loading={loadingFiltersState}
                                        control={control}
                                        menuPortalTarget={document.body}
                                        labeltext=''
                                        name='sinceWeek'
                                        display='vertical'
                                        variant='outlined'
                                        isClearable={true}
                                        isSearchable
                                        // onChange={handleClearSpecieValue}
                                        isMulti={false}
                                        req={true}
                                        defaultValue=''
                                        options={sinceOptionsState}
                                        placeholder='Bisemanal'
                                      />
                                    </div>
                                    <div className='input-group ml-md-2'>
                                      {/* <TextFieldWidgetDateController2
                                        name='until'
                                        labeltext='Hasta'
                                        placeholder='Hasta'
                                        display='vertical'
                                        variant='outlined'
                                        format='dd-MM-yyyy'
                                        style={{}}
                                        minheight={false}
                                        slotProps={{
                                          textField: {
                                            size: 'small',
                                          },
                                        }}
                                        sx={{
                                          width: '150px',
                                        }}
                                        // value={sinceSelectedState}
                                        // onChange={date => setSinceSelectedState(date)}
                                        minDate={minDateState}
                                        control={control}
                                        errors={errors.until}
                                        defaultValue={new Date()}
                                        // defaultValue={endColdDate}
                                      /> */}
                                      {/* <SelectMultipleWidget2
                                        errors={errors.untilWeek}
                                        loading={loadingFiltersState}
                                        control={control}
                                        menuPortalTarget={document.body}
                                        labeltext=''
                                        name='untilWeek'
                                        display='vertical'
                                        variant='outlined'
                                        isClearable={true}
                                        isSearchable
                                        // onChange={handleClearSpecieValue}
                                        isMulti={false}
                                        req={true}
                                        defaultValue=''
                                        options={untilOptionsState}
                                        placeholder='Bise. hasta'
                                      /> */}
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignSelf: 'flex-start',
                                        paddingTop: 5,
                                      }}
                                    >
                                      <SaveAction
                                        title='Filtrar'
                                        color='secondary'
                                        size='medium'
                                        className='roundedButton ml-md-2'
                                        variant='contained'
                                        disabled={loadingSubmitFilterState}
                                        messageType='add'
                                        mutationLoading={
                                          loadingSubmitFilterState
                                        }
                                        success={false}
                                      />
                                    </div>
                                  </form>
                                </Box>
                                <Box
                                  sx={{
                                    // marginRight: 2,
                                    marginBottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: 2,
                                  }}
                                >
                                  <TextField
                                    variant='outlined'
                                    placeholder='Buscar...'
                                    label='Buscar'
                                    size='small'
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position='start'>
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                      endAdornment: quickFilterText ? (
                                        <IconButton
                                          color='primary'
                                          aria-label='clear'
                                          component='span'
                                          onClick={() => onResetSearch()}
                                        >
                                          <ClearIcon
                                            fontSize='small'
                                            style={{
                                              color: theme.palette.grey[400],
                                            }}
                                          />
                                        </IconButton>
                                      ) : null,
                                    }}
                                    value={quickFilterText}
                                    onChange={handleQuickFilterChange}
                                    sx={{
                                      width: 200,
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <ButtonAction
                                    labelButton='GUARDAR'
                                    color='secondary'
                                    size='large'
                                    actionOnClick={onSaveData}
                                    className='roundedButton'
                                    variant='contained'
                                    messageType='add'
                                    actionClose={closeHandler}
                                    fullWidth
                                    disabled={
                                      loadingSubmitFilterState ||
                                      !rowData ||
                                      !rowData.length
                                    }
                                    mutationLoading={loadingSubmitState}
                                    success={successSubmitState}
                                  />
                                </Box>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 2,
                                  }}
                                >
                                  <IconButton
                                    color='primary'
                                    aria-label='export'
                                    onClick={handleMenuOpen}
                                    disabled={loadingSubmitFilterState}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem onClick={onBtnExportExcel}>
                                      Exportar a Excel
                                    </MenuItem>
                                    <MenuItem onClick={onBtnExportCsv}>
                                      Exportar a CSV
                                    </MenuItem>
                                  </Menu>
                                  <IconButton
                                    color='primary'
                                    aria-label='fullscreen-toggle'
                                    onClick={() =>
                                      setFullScreen(!fullScreenState)
                                    }
                                    disabled={loadingSubmitFilterState}
                                  >
                                    {fullScreenState ? (
                                      <FullscreenExitIcon />
                                    ) : (
                                      <FullscreenIcon />
                                    )}
                                  </IconButton>
                                </Box>
                              </div>
                              {errorDataState ? (
                                <Alert
                                  variant='filled'
                                  severity='error'
                                  onClose={() => {
                                    setErrorDataState(false);
                                  }}
                                  sx={{
                                    marginBottom: 2,
                                  }}
                                >
                                  NO SE HA PODIDO GUARDAR, COMUNICARSE CON
                                  SOPORTE.
                                </Alert>
                              ) : null}
                              <Alert severity='info'>
                                Sólo se habilitan para edición las Bi Semanales
                                que consideran el día de HOY y las pasadas. Las
                                bisemanales futuras no son editables.
                              </Alert>
                              {loadingSubmitFilterState && <LoadingWidget />}

                              {!loadingSubmitFilterState &&
                                (dataTableState && dataTableState.length ? (
                                  <div
                                    className='ag-theme-quartz'
                                    style={{ height: '740px' }}
                                  >
                                    <AgGridReact
                                      rowData={rowData}
                                      columnDefs={visibleColumnsState}
                                      onCellValueChanged={onCellValueChanged}
                                      pagination={true}
                                      paginationPageSize={50}
                                      suppressMovableColumns={true}
                                      suppressDragLeaveHidesColumns={true}
                                      paginationPageSizeSelector={
                                        paginationPageSizeSelector
                                      }
                                      localeText={localeText}
                                      defaultColDef={{
                                        flex: 1,
                                        minWidth: 150,
                                        filter: false,
                                        floatingFilter: true, // habilita el filtro dentro de la celda de la columna
                                      }}
                                      enableRangeSelection={false}
                                      // enableFillHandle={true}
                                      quickFilterText={quickFilterText}
                                      onGridReady={onGridReady}
                                      sideBar={sideBar}
                                      embedFullWidthRows={true}
                                      // isRowMaster={isRowMaster}
                                      // detailRowAutoHeight={true}
                                      getRowId={(params) => params.data.id}
                                      // grandTotalRow={'bottom'}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      height: '50%',
                                      width: '100%',
                                    }}
                                  >
                                    <Typography
                                      variant='h5'
                                      color='textSecondary'
                                    >
                                      No hay datos
                                    </Typography>
                                  </div>
                                ))}
                            </div>
                          </BsCol>
                        </BsRow>
                      </>
                    </>
                  </CardContent>
                </Card>
              </BsCol>
            </BsRow>
          </>
        </>
      </BsContainer>
    </div>
  );
};

export default CheckBiWeekly;
